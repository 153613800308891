import { graphql } from 'gatsby';
import React from 'react';
import { Grid, Segment } from 'semantic-ui-react';

import Content, { HTMLContent } from '../components/Content';
import DefaultPageLayout from '../components/DefaultPageLayout';

interface Props {
  data: {
    markdownRemark: {
      html: string;
      frontmatter: {
        title: string;
      };
    };
  };
}

export default ({ data }: Props) => {
  const { frontmatter, html } = data.markdownRemark;

  return (
    <DefaultPageLayout title={frontmatter.title}>
      <Segment style={{ padding: '8em 0em' }} vertical>
        <Grid container stackable verticalAlign="middle">
          <Grid.Row centered>
            <Grid.Column width={16}>
              <HTMLContent content={html} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </DefaultPageLayout>
  );
};

export const pageQuery = graphql`
  query PageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "page" } }) {
      html
      frontmatter {
        title
      }
    }
  }
`;
